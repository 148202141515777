import * as Sentry from "@sentry/remix";
import { add, type Duration } from "date-fns";
import { z } from "zod";

const payloadSchema = z.object({
  expireAt: z.number().nullish(),
  value: z.any(),
});

export type SetLocalStorageItemArgs = {
  expireAfter?: Duration;
  key: string;
  value: unknown;
};

export function setLocalStorageItem(args: SetLocalStorageItemArgs) {
  const expireAt = args.expireAfter
    ? add(new Date(), args.expireAfter).getTime()
    : undefined;

  const payload = {
    expireAt,
    value: args.value,
  } satisfies z.infer<typeof payloadSchema>;

  localStorage.setItem(args.key, JSON.stringify(payload));
}

export type GetLocalStorageItemArgs<Schema extends z.ZodTypeAny> = {
  key: string;
  schema: Schema;
};

export function getLocalStorageItem<Schema extends z.ZodTypeAny>(
  args: GetLocalStorageItemArgs<Schema>
): z.infer<Schema> | undefined {
  const item = localStorage.getItem(args.key);
  if (!item) {
    return undefined;
  }

  try {
    const payload = payloadSchema.parse(JSON.parse(item));

    const now = new Date().getTime();
    if (payload.expireAt && now > payload.expireAt) {
      localStorage.removeItem(args.key);
      return undefined;
    }

    return args.schema.parse(payload.value);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Failed to parse local storage item", {
      error,
      key: args.key,
    });
    Sentry.captureException(error);
    return undefined;
  }
}
